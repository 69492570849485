import { graphql, useStaticQuery } from 'gatsby'
import { useEffect, useState } from 'react'

// if slug is undefined return all bundles
const useBundle = slug => {
  const data = useStaticQuery(graphql`
    query bundleColletion {
      allContentfulBundle {
        bundles: nodes {
          name
          slug
          masterSku
          redirectSlug
          bundleSize
          omsTag
          yotpoId
          price
          mainImage {
            title
            gatsbyImageData
          }
          overheadProductImage {
            title
            gatsbyImageData
          }
        }
      }
    }
  `)

  const { bundles } = data.allContentfulBundle

  const [cmsBundle, setCmsBundle] = useState(null)

  useEffect(() => {
    // if slug is undefined return all bundles
    if (!slug) setCmsBundle(bundles)
    else {
      const bundle = bundles?.find(p => p.slug === slug)

      if (!bundle) return

      setCmsBundle(bundle)
    }
  }, [bundles, slug])

  return cmsBundle
}

export default useBundle
